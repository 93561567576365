import {BaseInformation} from './BaseInformation';
import {ArrayOfRegistration} from './ArrayOfRegistration';
import {Type} from 'class-transformer';

export class IntermediaryResponse {
  @Type(() => BaseInformation)
  informationBase: BaseInformation;
  @Type(() => ArrayOfRegistration)
  registrations: ArrayOfRegistration;
}


