import {Type} from 'class-transformer';
import {ExternalApiData} from './ExternalApiData';
import {SirenData} from './siren/SirenData';
import {OriasData} from './orias/OriasData';

export class LegalEntityExternalApiData extends ExternalApiData {
  @Type(() => SirenData)
  sirenApiData: SirenData;
  @Type(() => OriasData)
  oriasApiData: OriasData;
}
