import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {BooleanResponse} from '../../../../domain/BooleanResponse';
import {SearchParams} from '../../../../domain/SearchParams';
import {SirenData} from '../../domain/person/person/external-api-data/siren/SirenData';

@Injectable()
export class SireneService {
  private readonly baseUrl = '/api/sirene';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getSiretsWithSiren(siren: string): Observable<string[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(String)
            .get<string[]>(url + '/siren/' + siren + '/sirets')
        )
      );
  }

  public getCompanyInfoWithSiren(siren: string): Observable<SirenData> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SirenData)
            .get<SirenData>(url + '/siren/' + siren)
        )
      );
  }

  public legalEntitySirenIsRegistered(
    siren: string,
    personDataSourceName: string
  ): Observable<boolean> {
    return this.moduleService.coreModuleBackendUrl('/api/legal-entities').pipe(
      switchMap((url) =>
        this.http.get<boolean>(`${url}/siren/${siren}/registered`, {
          params: SearchParams.toHttpParams({
            personDataSourceName: personDataSourceName,
          })
        })
      )
    );
  }

  /**
   *
   * @param siren
   * @param personDataSourceName required unlike legalEntitySirenIsRegistered method
   */
  public legalEntitySirenIsRegisteredOnPersonDataSource(
    siren: string,
    personDataSourceName: string
  ): Observable<boolean> {
    return this.moduleService.coreModuleBackendUrl('/api/legal-entities').pipe(
      switchMap((url) =>
        this.http.get<boolean>(`${url}/personDataSource/${personDataSourceName}/siren/${siren}/registered`, {
          params: SearchParams.toHttpParams({
            personDataSourceName: personDataSourceName
          })
        })
      )
    );
  }

  public companySirenIsRegistered(
    siren: string,
  ): Observable<BooleanResponse> {
    return this.moduleService.coreModuleBackendUrl('/api/companies').pipe(
      switchMap((url) =>
        this.http.get<BooleanResponse>(`${url}/${siren}/registered`)
      )
    );
  }

  public isSirenNafAllowedForRegistration(siren: string): Observable<BooleanResponse> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http.get<BooleanResponse>(`${url}/registration/siren/${siren}/naf`)
      )
    );
  }
}
