import {Component, ElementRef, Input} from '@angular/core';
import {NotificationService} from '../../../services/notification.service';

@Component({
  selector: 'a-read-only',
  templateUrl: './a-read-only.component.html',
  styleUrls: ['./a-read-only.component.scss']
})
export class AReadOnlyComponent {
  @Input()
  labelKey: string;

  @Input()
  valueKey: string;

  @Input()
  horizontalLayout: boolean;

  @Input()
  clipboardMode: boolean = false;

  @Input()
  lightMode: boolean = false;

  @Input()
  smallWidthLabel: boolean;

  @Input()
  separator: boolean;

  @Input()
  noWrap: boolean;

  @Input()
  breakWord: boolean;

  @Input()
  displayDashIfEmpty: boolean = false;

  constructor(
    private notificationService: NotificationService
  ) {}

  public notify(): void {
    this.notificationService
      .success('notification.copyToClipboard')
      .subscribe();
  }
}
