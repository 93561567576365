import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {NaturalPersonSearchParam} from '../../domain/person/person/natural/NaturalPersonSearchParam';
import {PaginatedNaturalPersons} from '../../domain/person/person/natural/PaginatedNaturalPersons';
import {SearchParams} from '../../../../domain/SearchParams';
import {NaturalPerson} from '../../domain/person/person/natural/NaturalPerson';
import {PersonDataSourceService} from './person-data-source.service';
import {
  PaginatedNaturalPersonSearchElements
} from '../../domain/person/person/natural/PaginatedNaturalPersonSearchElements';
import {PoliticalExposure} from '../../domain/person/person/natural/redflag/PoliticalExposure';
import {NaturalPersonIntegratedData} from '../../domain/person/person/natural/NaturalPersonIntegratedData';
import {NaturalPersonPepStatus} from '../../domain/person/person/natural/redflag/NaturalPersonPepStatus';
import {Identity} from '../../domain/person/person/natural/main-information/Identity';
import {BasePersonService} from './base-person.service';
import {PersonType} from '../../domain/person/person/PersonType';
import {PaginatedIndicatorNaturalPersonSearchElements} from '../../domain/person/person/control/PaginatedIndicatorNaturalPersonSearchElements';

@Injectable()
export class NaturalPersonService extends BasePersonService<NaturalPerson>{
  protected override readonly baseUrl = '/api/natural-persons';
  protected override _currentPerson = new BehaviorSubject<NaturalPerson>(undefined);
  override personType: PersonType = PersonType.NATURAL;

  constructor(
    http: HttpHandlerService,
    moduleService: ModuleService,
    personDataSourceService: PersonDataSourceService
  ) {
    super(http, moduleService, personDataSourceService, NaturalPerson)
  }

  public findPaginated(
    searchParam: NaturalPersonSearchParam
  ): Observable<PaginatedNaturalPersonSearchElements> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaginatedNaturalPersonSearchElements)
          .post<PaginatedNaturalPersonSearchElements>(url + '/paginated', searchParam)
      )
    );
  }

  public findPaginatedForIndicator(
    searchParam: NaturalPersonSearchParam
  ): Observable<PaginatedIndicatorNaturalPersonSearchElements> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaginatedIndicatorNaturalPersonSearchElements)
          .post<PaginatedIndicatorNaturalPersonSearchElements>(url + '/paginated-for-controlType', searchParam)
      )
    );
  }

  public find(
    param: NaturalPersonSearchParam
  ): Observable<PaginatedNaturalPersons> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaginatedNaturalPersons)
          .get<PaginatedNaturalPersons>(url, {
            params: SearchParams.toHttpParams(param)
          })
      )
    );
  }

  public updatePersonIntegratedData(
    personId: string,
    personIntegratedData: NaturalPersonIntegratedData
  ): Observable<NaturalPerson> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(NaturalPerson)
            .put<NaturalPerson>(`${url}/${personId}/person-integrated-data`, personIntegratedData)
        )
      );
  }

  public updatePoliticalExposureQuestionnaire(
    personId: string,
    politicalExposure: PoliticalExposure
  ): Observable<NaturalPerson> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(NaturalPerson)
            .put<NaturalPerson>(`${url}/${personId}/political-exposure-questionnaire`, politicalExposure)
        )
      );
  }

  public updatePersonPepStatus(
    personId: string,
    pepStatus: NaturalPersonPepStatus,
    personIdentity: Identity
  ): Observable<NaturalPerson> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(NaturalPerson)
            .put<NaturalPerson>(`${url}/${personId}/pep-status/${pepStatus}`, personIdentity)
        )
      );
  }
}
