import {Transform, Type} from 'class-transformer';
import {Reference} from '../../../../../../domain/Reference';
import {LocalDate} from '../../../../../../domain/LocalDate';
import {LocalDateTransformer} from '../../../../../../domain/transformation/LocalDateTransformer';
import {SelectableRef} from '../../../../../../domain/SelectableRef';
import {LegalEntityIntegratedData} from './LegalEntityIntegratedData';
import {LegalEntityExternalApiData} from '../external-api-data/LegalEntityExternalApiData';
import {Person} from '../Person';

export class LegalEntity extends Person {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'legalEntity';

  @Transform(LocalDateTransformer.transformTo())
  creationDate: LocalDate;

  @Type(() => LegalEntityIntegratedData)
  personIntegratedData: LegalEntityIntegratedData = new LegalEntityIntegratedData();

  @Type(() => LegalEntityExternalApiData)
  externalApiData: LegalEntityExternalApiData;

  constructor() {
    super('com.beclm.core.api.person.legalEntity.LegalEntity');
  }

  public static toSelectableRef(
    reference: Reference<LegalEntity>
  ): SelectableRef<LegalEntity> {
    const selectableRef = new SelectableRef<LegalEntity>();
    selectableRef.id = reference;
    if (reference.isResolved()) {
      selectableRef.__label = reference.entity.personIntegratedData.name;
    } else {
      selectableRef.__label = reference.id;
    }
    return selectableRef;
  }

  public toSelectableRef(): SelectableRef<LegalEntity> {
    const selectableRef = new SelectableRef<LegalEntity>();
    selectableRef.id = new Reference<LegalEntity>(this);
    selectableRef.__label = this.personIntegratedData.name;
    return selectableRef;
  }
}
