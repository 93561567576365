import {Component, OnInit} from '@angular/core';
import {CompanyRegistrationAddress} from '../../../../domain/registration/CompanyRegistration';
import {
  CompanyService,
  Enterprise,
  RegistrationProfile,
  SessionService,
  SirenData,
  StepSingleFormComponent
} from 'base';
import {RegistrationService} from '../../../../services/registration.service';
import {StepData} from '../../register.component';

@Component({
  selector: 'm-registration-company-creation-step-three',
  templateUrl: './m-registration-company-creation-step-three.component.html',
  styleUrls: ['./m-registration-company-creation-step-three.component.scss']
})
export class MRegistrationCompanyCreationStepThreeComponent
  extends StepSingleFormComponent<StepData>
  implements OnInit {
  companyPublished = true;
  isCompanyAddressComplete = true;
  registrationProfileEnum = RegistrationProfile;
  hidePreviousButton = true;

  constructor(
    private registrationService: RegistrationService,
    private companyService: CompanyService,
    private sessionService: SessionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadCompanyIfCreated();
  }

  loadCompanyIfCreated(): void {
    this.sessionService.currentUser().subscribe((user) => {
      if (user.userRegistrationProgress.companyRef != null) {
        this.companyService.current().subscribe((value) => {
          this.data.companyRegistration.name = value.name;
          this.data.companyRegistration.siren = value.siren;
          this.data.companyRegistration.headcount = value.legalEntity.personIntegratedData.headcount;
          this.data.companyRegistration.ageaNumber = value.legalEntity.personIntegratedData.ageaNumber;
          if (value.invoiceInfo.invoiceAddress != null) {
            this.data.companyRegistration.address = new CompanyRegistrationAddress();
            this.data.companyRegistration.address.address = value.invoiceInfo.invoiceAddress.address;
            this.data.companyRegistration.address.city = value.invoiceInfo.invoiceAddress.city;
            this.data.companyRegistration.address.zipCode = value.invoiceInfo.invoiceAddress.zipCode;
          }
        });
      }
    });
  }

  onNextHandler = (
    success: () => void,
    error: (showErrorButton: boolean) => void
  ): void => {
    if (this.companyPublished && this.isCompanyAddressComplete) {
      this.data.companyRegistration.address = null;
    }
    this.registrationService
      .createCompany(this.data.companyRegistration)
      .subscribe(
        () => {
          success();
        },
        () => {
          error(false);
        }
      );
  };

  public onSirenDataChange(sirenData: SirenData): void {
    if (sirenData) {
      this.companyPublished = sirenData.enterprise.siege.publicationStatus;
      this.isCompanyAddressComplete = this.checkCompanyAddressCompletion(sirenData.enterprise);
      if (this.companyPublished) {
        this.data.companyRegistration.name = sirenData.enterprise.organizationName;
        this.data.companyRegistration.address = new CompanyRegistrationAddress();
        this.data.companyRegistration.address.address = sirenData.enterprise.siegeAddress;
        this.data.companyRegistration.address.zipCode = sirenData.enterprise.siege.address.postalCode;
        this.data.companyRegistration.address.city = sirenData.enterprise.siege.address.communeName;
      }
      this.data.canAccessNextStep = true;
    } else {
      this.data.companyRegistration.name = null;
      this.data.companyRegistration.address = new CompanyRegistrationAddress();
      this.data.canAccessNextStep = false;
    }
  }

  private checkCompanyAddressCompletion(enterprise: Enterprise) {
    return enterprise.siegeAddress.trim() !== ""
      && enterprise.siege.address.postalCode.trim() !== ""
      && enterprise.siege.address.communeName.trim() !== "";
  }
}
