import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {catchError, first, map} from 'rxjs/operators';
import {SireneService} from '../modules/core/services/person/sirene.service';
import {LegalEntity} from '../modules/core/domain/person/person/legal-entity/LegalEntity';
import {CustomValidators} from '../utils/CustomValidators';
import {SirenData} from '../modules/core/domain/person/person/external-api-data/siren/SirenData';

export class SirenIsStruckOffValidator {
  constructor(
    private sireneService: SireneService,
    private sirenIsStruckOff = false
  ) {}

  validate(
    control: AbstractControl
  ): Observable<ValidationErrors | null> {
    if (!this.sirenIsStruckOff) {
      return of(null);
    }

    return this.sirenIsStruckOffEnabledCheck(this.sireneService, control);
  }

  sirenIsStruckOffEnabledCheck(
    sirenService: SireneService,
    control: AbstractControl
  ): Observable<ValidatorFn> {
    return sirenService
      .getCompanyInfoWithSiren(control.value)
      .pipe(first())
      .pipe(
        catchError(() => {
          return of(null);
        })
      )
      .pipe(
        map((sirenData: SirenData) => {
          return this.sirenIsStruckOffValidatorFn(control, sirenData);
        })
      );
  }

  sirenIsStruckOffValidatorFn(
    control: AbstractControl,
    sirenData: SirenData
  ): ValidatorFn {
    if (
      control.value != null &&
      sirenData != null &&
      sirenData.enterprise != null &&
      sirenData.enterprise.active == false
    ) {
      return CustomValidators.buildError('sirenIsStruckOff', true);
    } else {
      return null;
    }
  }
}
