import {PersonDataSource} from '../../data-source/PersonDataSource';
import {LocalDate} from '../../../../../../domain/LocalDate';
import {Reference} from '../../../../../../domain/Reference';
import {SortOrder} from '../../../../../../domain/Sorter';
import {Transform, Type} from 'class-transformer';
import {ReferenceTransformer} from '../../../../../../domain/transformation/ReferenceTransformer';
import {State} from '../../../../../../domain/State';
import {LocalDateTransformer} from '../../../../../../domain/transformation/LocalDateTransformer';
import {CodedValue} from '../CodedValue';
import {AbstractCriteria} from '../../../../../../domain/AbstractCriteria';
import {ControlType} from '../control/ControlType';

export class NaturalPersonSearchParam extends AbstractCriteria {
  public readonly '@class': string = "com.beclm.core.api.person.naturalPerson.NaturalPersonSearchCriteria";
  externalRefId: string;
  lastName: string;
  @Transform(LocalDateTransformer.transformTo())
  birthDate: LocalDate;
  @Type(() => CodedValue)
  postalAddressCountry: CodedValue[] = [];
  @Type(() => CodedValue)
  csp: CodedValue[] = [];
  @Transform(ReferenceTransformer.transformTo(PersonDataSource))
  personDataSourceRefs: Reference<PersonDataSource>[] = [];
  controlStatuses: {[key: string]: State[]} = {};

  // Filters only used in indicator person search
  controlType: ControlType;
  minScore: number;
  maxScore: number;

  currentPage: number;
  nbOfResultsPerPage: number;
  sort: NaturalPersonSearchSort;
  sortOrder: SortOrder;
  ergonomicSearch = false;
}

export enum NaturalPersonSearchSort {
  BY_EXTERNAL_REF_ID = 'BY_EXTERNAL_REF_ID',
  BY_FIRST_NAME = 'BY_FIRST_NAME',
  BY_LAST_NAME = 'BY_LAST_NAME',

  // Sort only used in person search
  BY_BIRTH_DATE = 'BY_BIRTH_DATE',

  // Sorts only used in indicator person search
  BY_DESCRIPTION = 'BY_DESCRIPTION',
  BY_SCORE = 'BY_SCORE'
}
