import {Transform, Type} from 'class-transformer';
import {Reference} from '../../../../../../domain/Reference';
import {AuditData} from '../../../../../../domain/AuditData';
import {PersonDataSource} from '../../data-source/PersonDataSource';
import {LocalDate} from '../../../../../../domain/LocalDate';
import {LocalDateTransformer} from '../../../../../../domain/transformation/LocalDateTransformer';
import {ReferenceTransformer} from '../../../../../../domain/transformation/ReferenceTransformer';
import {State} from '../../../../../../domain/State';

export class NaturalPersonSearchElement {
  id: string;
  auditData: AuditData;
  editable: boolean;
  externalRefId: string;
  @Transform(ReferenceTransformer.transformTo(PersonDataSource))
  personDataSourceRef: Reference<PersonDataSource>;
  lastName: string;
  firstName: string;
  @Transform(LocalDateTransformer.transformTo())
  birthDate: LocalDate;
  @Type(() => Map)
  counterByStatus: Map<State, number>;
}
