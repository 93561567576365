import {Type} from 'class-transformer';
import {ControlFamily} from './ControlFamily';
import {ControlType} from './ControlType';
import {ControlCriticality} from './ControlCriticality';

export class IndicatorSearchElement {
  @Type(() => ControlFamily)
  controlFamily: ControlFamily;
  controlType: ControlType;
  controlCriticality: ControlCriticality;
  numberToQualify: number;
  numberNoRisk: number;
  numberNotComputable: number;
}
