import {Transform, Type} from 'class-transformer';
import {LegalEntity} from '../../legal-entity/LegalEntity';
import {ReferenceTransformer} from '../../../../../../../domain/transformation/ReferenceTransformer';
import {Reference} from '../../../../../../../domain/Reference';
import {LegalEntityWrapper} from '../../../../../../../domain/transformation/LegalEntityWrapper';

export class Contract {
  @Type(() => Date)
  startDate: Date;
  @Type(() => Date)
  endDate: Date;
  @Transform(ReferenceTransformer.transformTo(LegalEntityWrapper))
  legalEntityRef: Reference<LegalEntity>;
}
