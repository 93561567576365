import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {CodedValue} from '../../domain/person/person/CodedValue';
import {HierarchizedCode} from '../../domain/person/person/HierarchizedCode';
import {HierarchizedCodeSearchParam} from '../../domain/person/person/HierarchizedCodeSearchParam';
import {SearchParams} from '../../../../domain/SearchParams';

@Injectable()
export class CodedValueService {
  private readonly baseUrl = '/api/coded-value';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getRootValues(type: string): Observable<CodedValue[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) => this.http
          .transformResponseTo(CodedValue)
          .get<CodedValue[]>(url + '/enums/' + type))
      );
  }

  public getValuesFor(
    type: string,
    parentCode: string
  ): Observable<CodedValue[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(CodedValue)
            .get<CodedValue[]>(url + '/enums/' + type + '/' + parentCode)
        )
      );
  }

  public getLastLevelHierarchizedValues(type: string): Observable<HierarchizedCode[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(HierarchizedCode)
            .get<HierarchizedCode[]>(url + '/lastLevel/enums/' + type)
        )
      );
  }

  public searchValues(
    type: string,
    searchParam: HierarchizedCodeSearchParam
  ): Observable<HierarchizedCode[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
           this.http
            .transformResponseTo(HierarchizedCode)
            .get<HierarchizedCode[]>(url + '/enums/search/' + type, {
              params: SearchParams.toHttpParams(searchParam)
            })
        )
      );
  }
}
