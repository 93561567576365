import {NgModule} from '@angular/core';
import {CompanyService} from './company/company.service';
import {CompanyTypeService} from './company/company-type.service';
import {SubscriptionService} from './company/subscription.service';
import {SecurityPolicyService} from './user/security-policy.service';
import {UserService} from './user/user.service';
import {UserCredentialService} from './user/user-credential.service';
import {PersonControlsService} from './person/person-controls.service';
import {PersonDataCategoryService} from './person/person-data-category.service';
import {SireneService} from './person/sirene.service';
import {CoreCompanyConfigService} from './person/core-company-config.service';
import {RoleService} from './user/role.service';
import {ServiceDeskService} from './adapter/servicedesk/service-desk.service';
import {CoreConfigService} from './core/core-config.service';
import {PersonLinkTypeService} from './person/person-link-type.service';
import {PersonDataSourceService} from './person/person-data-source.service';
import {LegalEntityService} from './person/legal-entity.service';
import {NaturalPersonService} from './person/natural-person.service';
import {PersonService} from './person/person.service';
import {CompanyCreationModelService} from './companyCreationModel/company-creation-model.service';
import {SendSubscriptionRequestService} from './adapter/servicedesk/send-subscription-request.service';
import {ControlTypeService} from './person/control-type.service';
import {CodedValueService} from './person/coded-value.service';
import {ScoredCodedValueService} from './person/scored-coded-value.service';
import {SessionCoreCompanyConfigService} from './person/session-core-company-config.service';
import {RuleService} from './cgu/rule.service';
import {ExportDescriptorService} from './export/export-descriptor.service';
import {PersonControlResultService} from './personControlResult/person-control-result.service';
import {
  CorePersonControlResultArchiverSecurityService
} from './personControlResult/core-person-control-result-archiver-security.service';
import {ExportTaskService} from './export/export-task.service';
import {SubscriptionProposalService} from './subscriptionProposal/subscription-proposal.service';
import {
  QualificationSummaryQuestionEntityService
} from '../../blacklist-pep/services/qualification-summary-question-entity.service';
import {UserGuestsService} from './userGuest/user-guests.service';
import {ControlFamilyService} from './person/control-family.service';
import {PersonCoreIndicatorService} from './core/person-core-indicator.service';
import {SearchParamByCompanyService} from './person/search-param-by-company.service';

@NgModule({
  imports: [],
  providers: [
    CompanyService,
    CompanyTypeService,
    SubscriptionService,
    SecurityPolicyService,
    UserService,
    UserCredentialService,
    PersonControlsService,
    PersonDataCategoryService,
    NaturalPersonService,
    SireneService,
    CoreCompanyConfigService,
    RoleService,
    ServiceDeskService,
    SendSubscriptionRequestService,
    CoreConfigService,
    PersonLinkTypeService,
    PersonDataSourceService,
    LegalEntityService,
    PersonCoreIndicatorService,
    PersonService,
    CompanyCreationModelService,
    ControlTypeService,
    CodedValueService,
    ScoredCodedValueService,
    SessionCoreCompanyConfigService,
    SearchParamByCompanyService,
    RuleService,
    PersonControlResultService,
    CorePersonControlResultArchiverSecurityService,
    RuleService,
    ExportDescriptorService,
    ExportTaskService,
    SubscriptionProposalService,
    QualificationSummaryQuestionEntityService,
    UserGuestsService,
    ControlFamilyService
  ]
})
export class BaseCoreServicesModule {}
