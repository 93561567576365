import {AuditData} from '../../../../../../domain/AuditData';
import {Transform, Type} from 'class-transformer';
import {ReferenceTransformer} from '../../../../../../domain/transformation/ReferenceTransformer';
import {PersonDataSource} from '../../data-source/PersonDataSource';
import {Reference} from '../../../../../../domain/Reference';
import {ControlResult} from './ControlResult';

export class IndicatorNaturalPersonSearchElement {
  id: string;
  auditData: AuditData;
  editable: boolean;
  externalRefId: string;
  @Transform(ReferenceTransformer.transformTo(PersonDataSource))
  personDataSourceRef: Reference<PersonDataSource>;
  lastName: string;
  firstName: string;
  @Type(() => ControlResult)
  controlResult: ControlResult;
}
