export class Sorter {
  public sort: string;
  public order: SortOrder;

  constructor(sort: string, order: SortOrder = SortOrder.ASC) {
    this.sort = sort;
    this.order = order;
  }
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface Sort {}
