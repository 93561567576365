<div class="cell"
     [ngClass]="{'shrink grid-y': displaySirenDataInfo, 'auto': !displaySirenDataInfo}">
  <div class="cell shrink">
    <a-input
      name="sirenInput"
      [inputType]="'text'"
      [ngModel]="siren"
      (ngModelChange)="onSirenChange($event)"
      [disabled]="!editable"
      [required]="required"
      [label]="'siren.label' | translate"
    >
    </a-input>
    <div
      class="red error-message"
      *ngIf="sirenHasInvalidNaf"
    >
      {{'siren.error.invalid.naf' | translate}}
    </div>
    <div
      class="red error-message"
      *ngIf="sirenIsStruckOff"
    >
      {{'siren.error.struck.off' | translate}}
    </div>
    <div
      class="red error-message"
      *ngIf="sirenIsInvalid"
    >
      {{'siren.error.notValid' | translate}}
    </div>
    <div
      class="red error-message"
      *ngIf="sirenIsUnique"
    >
      {{'siren.error.already.exist' | translate}}
    </div>
  </div>
  <div class="cell shrink no-padding text-right" *ngIf="editable">
    <spacer [size]="8"></spacer>
    <a-button-default
      [labelKey]="'check.siren' | translate"
      [faIcon]="'fa fa-eye'"
      [disabled]="!hasErrors || !siren"
      (btnClick)="checkSiren()"
    ></a-button-default>
    <spacer [size]="8"></spacer>
  </div>
</div>

<div
  class="cell auto grid-x grid-padding-x grid-margin-x sirenCheckResult"
  *ngIf="!checkSirenError && editable && displaySirenDataInfo"
>
  <ng-container *ngIf="!sirenData && checkSirenPlaceHolder">
    <div class="cell auto">
      <span>{{this.checkSirenPlaceHolder}}</span>
    </div>
  </ng-container>

  <ng-container *ngIf="sirenData && sirenData.enterprise.siege?.publicationStatus">
    <div class="cell small-24">
      <a-read-only
        [labelKey]="'legal.entity.name' | translate"
        [valueKey]="sirenData.enterprise.organizationName"
      ></a-read-only>
    </div>
    <spacer [size]="8"></spacer>
    <div class="cell small-24">
      <a-read-only
        [labelKey]="'legal.entity.street' | translate"
        [valueKey]="sirenData.enterprise.siege ? (
          ((sirenData.enterprise.siege.address?.streetNumber) || '') + ' ' +
          ((sirenData.enterprise.siege.address?.streetType) || '') + ' ' +
          ((sirenData.enterprise.siege.address?.streetDescription) || '')) : ''"
      ></a-read-only>
    </div>
    <spacer [size]="8"></spacer>
    <div class="cell shrink">
      <a-read-only
        [labelKey]="'legal.entity.cp' | translate"
        [valueKey]="sirenData.enterprise.siege?.address?.postalCode"
      ></a-read-only>
    </div>
    <div class="cell shrink">
      <a-read-only
        [labelKey]="'legal.entity.city' | translate"
        [valueKey]="sirenData.enterprise.siege?.address?.communeName"
      ></a-read-only>
    </div>
  </ng-container>

  <ng-container *ngIf="sirenData && !sirenData.enterprise.siege?.publicationStatus && checkPublicationStatus">
    <div class="cell auto grid-x red error-message _error">
      <div class="cell auto">
        <span>{{'check.siren.not.published' | translate}}</span>
      </div>
    </div>
  </ng-container>
</div>

<ng-container *ngIf="checkSirenError && editable">
  <div
    class="cell auto grid-x red error-message sirenCheckResult _error"
    *ngIf="!sirenData"
  >
    <div class="cell auto">
      <span>{{'check.siren.error' | translate}}</span>
    </div>
  </div>
</ng-container>
