import {Type} from 'class-transformer';
import {ArrayOfIntermediaryResponse} from './ArrayOfIntermediaryResponse';
import {Error} from './Error';

export class IntermediarySearchResponse {
  @Type(() => ArrayOfIntermediaryResponse)
  intermediaries: ArrayOfIntermediaryResponse
  @Type(() => Error)
  error: Error
}
