import {ClassedEntity} from '../../../../../domain/ClassedEntity';
import {PersonDataSource} from '../data-source/PersonDataSource';
import {Reference} from '../../../../../domain/Reference';
import {Transform, Type} from 'class-transformer';
import {ReferenceTransformer} from '../../../../../domain/transformation/ReferenceTransformer';
import {NaturalPerson} from './natural/NaturalPerson';
import {LegalEntity} from './legal-entity/LegalEntity';
import {PersonType} from './PersonType';
import {SelectableRef} from '../../../../../domain/SelectableRef';
import {PersonIntegratedData} from './PersonIntegratedData';
import {ControlsData} from './control/ControlsData';

export class Person extends ClassedEntity {
  editable = false;

  externalRefId: string;
  @Transform(ReferenceTransformer.transformTo(PersonDataSource))
  personDataSourceRef: Reference<PersonDataSource>;

  @Type(() => PersonIntegratedData)
  personIntegratedData: PersonIntegratedData;
  @Type(() => ControlsData)
  controlsData: ControlsData;

  public static toSelectableRef(
    reference: Reference<Person>
  ): SelectableRef<Person> {
    if (reference.entity instanceof NaturalPerson) {
      const naturalReference = reference as Reference<NaturalPerson>;
      const selectableRef = new SelectableRef<NaturalPerson>();
      selectableRef.id = naturalReference;
      if (naturalReference.isResolved()) {
        selectableRef.__label =
          naturalReference.entity.personIntegratedData.identity.email +
          ' - ' +
          naturalReference.entity.personIntegratedData.identity.lastName +
          ' ' +
          naturalReference.entity.personIntegratedData.identity.firstName;
      } else {
        selectableRef.__label = naturalReference.id;
      }
      return selectableRef;
    } else if (reference.entity instanceof LegalEntity) {
      const selectableRef = new SelectableRef<LegalEntity>();
      const legalEntityReference = reference as Reference<LegalEntity>;
      selectableRef.id = legalEntityReference;
      if (legalEntityReference.isResolved()) {
        selectableRef.__label = legalEntityReference.entity.personIntegratedData.name;
      } else {
        selectableRef.__label = legalEntityReference.id;
      }
      return selectableRef;
    }
  }

  public toSelectableRef(): SelectableRef<Person> {
    if (this instanceof NaturalPerson) {
      const selectableRef = new SelectableRef<Person>();
      selectableRef.id = new Reference<Person>(this);
      selectableRef.__label =
        this.personIntegratedData.identity.email +
        ' - ' +
        this.personIntegratedData.identity.lastName +
        ' ' +
        this.personIntegratedData.identity.firstName;
      return selectableRef;
    } else if (this instanceof LegalEntity) {
      const selectableRef = new SelectableRef<LegalEntity>();
      selectableRef.id = new Reference<LegalEntity>(this);
      selectableRef.__label = this.personIntegratedData.name;
      return selectableRef;
    }
  }

  public getName(): string {
    if (this instanceof NaturalPerson) {
      let firstName = '';
      if (this.personIntegratedData.identity.firstName) {
        firstName = this.personIntegratedData.identity.firstName;
      }
      return `${firstName} ${this.personIntegratedData.identity.lastName}`;
    } else if (this instanceof LegalEntity) {
      return this.personIntegratedData.name;
    }
  }

  public getType(): string {
    if (this instanceof NaturalPerson) {
      return PersonType.NATURAL;
    } else if (this instanceof LegalEntity) {
      return PersonType.LEGAL;
    }
  }
}
