<button
  *ngIf="btnRouterLink"
  type="button"
  class="button _secondary hollow"
  [class.expanded]="expanded"
  [class._small]="small"
  [disabled]="disabled || loading"
  (click)="btnClick.emit($event)"
  [routerLink]="btnRouterLink"
  [queryParams]="btnRouterLinkQueryParams"
>
  <a-button-text [labelKey]="labelKey" [loading]="loading"></a-button-text>
</button>
<button
  *ngIf="!btnRouterLink"
  type="button"
  class="button _secondary hollow"
  [class.expanded]="expanded"
  [class._small]="small"
  [disabled]="disabled || loading"
  (click)="btnClick.emit($event)"
>
  <a-button-text [labelKey]="labelKey" [loading]="loading"></a-button-text>
</button>
