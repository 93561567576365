import {Type} from "class-transformer";
import {LocalDate} from '../../../../../../../domain/LocalDate';
import {FormGroup} from '@angular/forms';

export class InsurerContract {
  type: string;
  number: string;
  insurer: string;
  @Type(() => Date)
  lastModificationDate: Date;
  @Type(() => Date)
  startDate: LocalDate;
  @Type(() => Date)
  endDate: LocalDate;
}
