export enum ControlType {
  BlacklistControl = 'BlacklistControl',
  PepControl = 'PepControl',
  OriasControl = 'OriasControl',
  ResidenceControl = 'ResidenceControl',
  GafiControl = 'GafiControl',
  LegalEntityGafiControl = 'LegalEntityGafiControl',
  EUControl = 'EUControl',
  BaselControl = 'BaselControl',
  CspControl = 'CspControl',
  NafControl = 'NafControl',
  NaturalPersonNafControl = 'NaturalPersonNafControl',
  LegalEntityNafControl = 'LegalEntityNafControl',
  MilitaryControl = 'MilitaryControl',
  SirenControl = 'SirenControl',
  NationalityControl = 'NationalityControl',
  FlowEmitterCountryControl = 'FlowEmitterCountryControl',
  FlowReceiverCountryControl = 'FlowReceiverCountryControl',
  ExternalAssetsInFranceControl = 'ExternalAssetsInFranceControl',
  ExternalAssetsCountryControl = 'ExternalAssetsCountryControl',
  AccountFilingBodaccEventControl = 'AccountFilingBodaccEventControl',
  ModificationBodaccEventControl = 'ModificationBodaccEventControl',
  CreationBodaccEventControl = 'CreationBodaccEventControl',
  WriteOffBodaccEventControl = 'WriteOffBodaccEventControl',
  CollectiveBodaccEventControl = 'CollectiveBodaccEventControl',
  SalesBodaccEventControl = 'SalesBodaccEventControl',
  RegistrationBodaccEventControl = 'RegistrationBodaccEventControl',
  CoBrokerControl = 'CoBrokerControl',
  HeadcountRangeControl = 'HeadcountRangeControl'
}

export const NaturalPersonControlType = [
  ControlType.BlacklistControl,
  ControlType.PepControl,
  ControlType.ResidenceControl,
  ControlType.GafiControl,
  ControlType.EUControl,
  ControlType.BaselControl,
  ControlType.CspControl,
  ControlType.NafControl,
  ControlType.NaturalPersonNafControl,
  ControlType.MilitaryControl,
  ControlType.NationalityControl,
  ControlType.FlowEmitterCountryControl,
  ControlType.FlowReceiverCountryControl,
  ControlType.ExternalAssetsInFranceControl,
  ControlType.ExternalAssetsCountryControl
];
export const LegalEntityControlType = [
  ControlType.BlacklistControl,
  ControlType.OriasControl,
  ControlType.LegalEntityGafiControl,
  ControlType.LegalEntityNafControl,
  ControlType.SirenControl,
  ControlType.AccountFilingBodaccEventControl,
  ControlType.ModificationBodaccEventControl,
  ControlType.CreationBodaccEventControl,
  ControlType.WriteOffBodaccEventControl,
  ControlType.CollectiveBodaccEventControl,
  ControlType.SalesBodaccEventControl,
  ControlType.RegistrationBodaccEventControl,
  ControlType.CoBrokerControl,
  ControlType.HeadcountRangeControl
];
