import {Transform, Type} from 'class-transformer';
import {ClassedEntity} from '../../../../domain/ClassedEntity';
import {CompanyType} from './company-type/CompanyType';
import {Reference} from '../../../../domain/Reference';
import {Uri} from '../../../../domain/Uri';
import {ReferenceTransformer} from '../../../../domain/transformation/ReferenceTransformer';
import {SelectableRef} from '../../../../domain/SelectableRef';
import {CompanyInvoiceInfo} from './CompanyInvoiceInfo';
import {LegalEntity} from '../person/person/legal-entity/LegalEntity';

export class Company extends ClassedEntity {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'company';

  public name: string;
  @Type(() => CompanyType)
  public type: CompanyType = new CompanyType();
  public siren: string;
  public blobId: string;
  @Type(() => LegalEntity)
  public legalEntity: LegalEntity = new LegalEntity();
  public authorizedModules: Array<string>;
  public appendix: string;
  public toDelete: boolean;
  @Transform(ReferenceTransformer.transformTo(Company))
  public parentCompany: Reference<Company>;
  @Type(() => CompanyInvoiceInfo)
  public invoiceInfo: CompanyInvoiceInfo = new CompanyInvoiceInfo();
  public isBillable: boolean;

  constructor() {
    super('com.beclm.core.api.company.Company');
  }

  public static referenceFrom(id: string) {
    return new Reference<Company>(new Uri(this.MODULE, this.COLLECTION, id));
  }

  public static toSelectableRef(
    reference: Reference<Company>
  ): SelectableRef<Company> {
    const selectableRef = new SelectableRef<Company>();
    selectableRef.id = reference;
    if (reference.isResolved()) {
      selectableRef.__label = reference.entity.name;
    } else {
      selectableRef.__label = reference.id;
    }
    return selectableRef;
  }

  public toSelectableRef(): SelectableRef<Company> {
    const selectableRef = new SelectableRef<Company>();
    selectableRef.id = new Reference<Company>(this);
    selectableRef.__label = this.name;
    return selectableRef;
  }
}
