import {Sorter, SortOrder} from './Sorter';
import {BaseFilterData} from './BaseFilterData';
import {Pagination} from './Pagination';

export class AbstractCriteria implements BaseFilterData {
  static keySessionStorage: string;
  currentPage: number;
  nbOfResultsPerPage: number;
  sort: string;
  sortOrder: SortOrder = SortOrder.ASC;
  ergonomicSearch = true;

  toSorter(): Sorter {
    return new Sorter(this.sort, this.sortOrder);
  }

  toPagination(): Pagination {
    const pagination = new Pagination();
    pagination.currentPage = this.currentPage;
    pagination.nbOfResultsPerPage = this.nbOfResultsPerPage;
    return pagination;
  }
}
