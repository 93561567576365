import {PersonIntegratedData} from '../PersonIntegratedData';
import {Transform, Type} from 'class-transformer';
import {LocalDateTransformer} from '../../../../../../domain/transformation/LocalDateTransformer';
import {LocalDate} from '../../../../../../domain/LocalDate';
import {CodedValue} from '../CodedValue';
import {PostalAddress} from '../natural/main-information/PostalAddress';
import {Contract} from '../natural/main-information/Contract';
import {ReferenceTransformer} from '../../../../../../domain/transformation/ReferenceTransformer';
import {NaturalPerson} from '../natural/NaturalPerson';
import {Reference} from '../../../../../../domain/Reference';
import {HeadcountRange} from '../../../../../core-shared/domain/person/HeadcountRange';
import {LegalEntityExtraData} from './LegalEntityExtraData';

export class LegalEntityIntegratedData extends PersonIntegratedData {
  sirenCode: string;
  name: string;
  @Transform(LocalDateTransformer.transformTo())
  creationDate: LocalDate;
  oriasId: string;
  @Type(() => CodedValue)
  naf: CodedValue;
  @Type(() => PostalAddress)
  postalAddress: PostalAddress = new PostalAddress();
  @Transform(LocalDateTransformer.transformTo())
  registrationDate: LocalDate;
  issuingOffice: string;
  @Type(() => Contract)
  contract: Contract = new Contract();
  siret: string;
  legalCategory: string;
  headcount: number;
  headcountRange: HeadcountRange;
  @Transform(ReferenceTransformer.transformTo(NaturalPerson))
  recipientRefs: Reference<NaturalPerson>[];
  ageaNumber: string;
  legalEntityExtraData: LegalEntityExtraData = new LegalEntityExtraData();
}
