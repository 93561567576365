import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {switchMap} from 'rxjs/operators';
import {PersonType} from '../../domain/person/person/PersonType';
import {SearchParamByCompany} from '../../../../domain/SearchParamByCompany';

@Injectable()
export class SearchParamByCompanyService {
  private readonly baseUrl = '/api/searchParam';
  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getSearchParamByCompany(personType: PersonType): Observable<SearchParamByCompany[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SearchParamByCompany)
            .get<SearchParamByCompany[]>(url, {
              params: {type: personType}
            })
        )
    );
  }

  public findById(id: string): Observable<SearchParamByCompany> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SearchParamByCompany)
            .get<SearchParamByCompany>(url+"/"+id)
        )
      );
  }

  public createSearchParamByCompany(param: SearchParamByCompany) {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SearchParamByCompany)
            .post<SearchParamByCompany>(url, param)
        )
      );
  }

  public updateSearchParamByCompany(param: SearchParamByCompany) {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(SearchParamByCompany)
            .put<SearchParamByCompany>(url+"/"+param.id, param)
        )
      );
  }

  public deleteSearchParamByCompany(criteriaId: string): Observable<void> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .delete<void>(url+"/"+criteriaId)
        )
      );
  }

}
