import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {PersonCoreIndicatorCompanyTotal} from '../../domain/person/person-core-indicator/PersonCoreIndicator';
import {Reference} from '../../../../domain/Reference';
import {Company} from '../../domain/company/Company';
import {IndicatorSearchParam} from '../../domain/person/person/control/IndicatorSearchParam';
import {PersonType} from '../../domain/person/person/PersonType';
import {PaginatedIndicatorSearchElements} from '../../domain/person/person/control/PaginatedIndicatorSearchElements';
import {SearchParams} from '../../../../domain/SearchParams';

@Injectable()
export class PersonCoreIndicatorService {
  private readonly baseUrl = '/api/person-core-indicators';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public findPaginated(
    personType: PersonType,
    indicatorSearchParam: IndicatorSearchParam
  ): Observable<PaginatedIndicatorSearchElements> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(PaginatedIndicatorSearchElements)
            .get<PaginatedIndicatorSearchElements>(
              `${url}/paginated/person-type/${personType}`,
              {params: SearchParams.toHttpParams(indicatorSearchParam)}
            )
        )
      );
  }

  public findByCompanyRef(
    companyRef: Reference<Company>
  ): Observable<PersonCoreIndicatorCompanyTotal> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
                  this.http
                    .transformResponseTo(PersonCoreIndicatorCompanyTotal)
                    .get<PersonCoreIndicatorCompanyTotal>(`${url}/companyRef`, {
                      params: {companyRef: companyRef}
                    })
      )
    );
  }
}
