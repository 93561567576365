import {PersonRiskStatus} from '../risk/PersonRiskStatus';
import {Type} from 'class-transformer';
import {ControlResult} from './ControlResult';

export class ControlsData {
  riskStatus: PersonRiskStatus;
  @Type(() => Date)
  lastControlDate: Date;
  controlsToBeComputed: boolean = false;
  @Type(() => ControlResult)
  controlResults: ControlResult[] = [];

}
