import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {ControlFamily} from '../../domain/person/person/control/ControlFamily';

@Injectable({
  providedIn: 'root'
})
export class ControlFamilyService {
  private readonly baseUrl: string = '/api/control-family';

  constructor(
    private http: HttpHandlerService,
    private moduleService: ModuleService
  ) {}

  public getById(id: string): Observable<ControlFamily> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ControlFamily)
            .get<ControlFamily>(`${url}/${id}`)
        )
      );
  }

  public getAllFamilies(): Observable<ControlFamily[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(ControlFamily)
            .get<ControlFamily[]>(`${url}`)
        )
      );
  }
}
