import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, timer} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {SearchParams} from '../../../../domain/SearchParams';
import {Reference} from '../../../../domain/Reference';
import {BooleanResponse} from '../../../../domain/BooleanResponse';
import {PersonDataSourceService} from './person-data-source.service';
import {InsurerContract} from '../../domain/person/person/natural/main-information/InsurerContract';
import {ControlType} from '../../domain/person/person/control/ControlType';
import {State} from '../../../../domain/State';
import {ControlResult} from '../../domain/person/person/control/ControlResult';
import {Person} from '../../domain/person/person/Person';
import {PersonModification} from '../../domain/person/person/PersonModification';
import {PersonType} from '../../domain/person/person/PersonType';
import {PersonDataSource} from '../../domain/person/data-source/PersonDataSource';
import {AbstractCriteria} from '../../../../domain/AbstractCriteria';

@Injectable()
export abstract class BasePersonService<T extends Person> {
  protected abstract readonly baseUrl;
  protected abstract _currentPerson = new BehaviorSubject<T>(undefined);
  abstract personType: PersonType

  protected constructor(
    protected http: HttpHandlerService,
    protected moduleService: ModuleService,
    protected personDataSourceService: PersonDataSourceService,
    protected readonly newable: new () => T
  ) {}

  public loadCurrentPerson(person: T) {
    this._currentPerson.next(person);

    const subscription = timer(500, 1000).subscribe((t) => {
      this.getById(person.id).subscribe((personControlled) => {
        if (!personControlled.controlsData.controlsToBeComputed) {
          this._currentPerson.next(personControlled);
          subscription.unsubscribe();
        }
      });
    });
  }

  public getById(id: string): Observable<T> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(this.newable)
            .get<T>(url + '/' + id)
        )
      );
  }

  public findIds(
    searchParam: AbstractCriteria
  ): Observable<Array<string>> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .post<Array<string>>(url + '/find-ids', searchParam)
      )
    );
  }

  public createPerson(
    personModification: PersonModification,
    asyncControl: boolean = true
  ): Observable<T> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(this.newable)
            .post<T>(url, personModification, {
              params: {
                asyncControl: asyncControl
              }
            })
        )
      );
  }

  public deletePerson(id: string): Observable<string> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(switchMap((url) => this.http.delete<string>(url + '/' + id)));
  }

  public updatePerson(
    personModification: PersonModification
  ): Observable<T> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(this.newable)
            .put<T>(url, personModification)
        )
      );
  }

  public updateContracts(
    personId: string,
    contracts: InsurerContract[]
  ): Observable<T> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(this.newable)
            .put<T>(`${url}/${personId}/contracts`, contracts)
        )
      );
  }


  public save(
    personModification: PersonModification
  ): Observable<T> {
    let personDataSource: PersonDataSource
    if (this.personType === PersonType.NATURAL) {
      personDataSource = this.personDataSourceService.getCurrentNaturalPersonDataSource()
    } else  {
      personDataSource = this.personDataSourceService.getCurrentLegalEntityDataSource()
    }
    personModification.personDataSourceRef = new Reference(personDataSource);

    if (personModification.id !== undefined) {
      return this.updatePerson(personModification);
    } else {
      return this.createPerson(personModification);
    }
  }

  public qualify(
    personId: string,
    controlType: ControlType,
    fromStatus: State,
    qualifyStatus: State,
    comment: string,
  ): Observable<ControlResult> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(ControlResult)
          .post<ControlResult>(`${url}/${personId}/qualify/${controlType}`, {
            fromStatus: fromStatus,
            qualifyStatus: qualifyStatus,
            comment: comment,
          })
      )
    )
  }

  public controlPerson(
    person: PersonModification
  ): Observable<T> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(this.newable)
          .post<T>(`${url}/control`, person)
      )
    );
  }

  public recomputeControlType(personId: string, controlType: ControlType): Observable<T> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(this.newable)
          .put<T>(`${url}/control/person/${personId}/recompute/${controlType}`, {})
      )
    )
  }
}
