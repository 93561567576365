<button
  *ngIf="btnRouterLink"
  type="button"
  class="button"
  [class.expanded]="expanded"
  [class.hollow]="hollow"
  [class._small]="small"
  [class._white]="white"
  [class._alert]="alert"
  [class._warning]="warning"
  [class._highlight]="highlight"
  [class._success]="success"
  [disabled]="disabled || loading"
  (click)="btnClick.emit($event)"
  [routerLink]="btnRouterLink"
  [queryParams]="btnRouterLinkQueryParams"
>
  <i [ngClass]="faIcon" *ngIf="faIcon"></i>
  <a-button-text [labelKey]="labelKey" [loading]="loading"></a-button-text>
</button>

<button
  *ngIf="!btnRouterLink"
  type="button"
  class="button"
  [class.expanded]="expanded"
  [class.hollow]="hollow"
  [class._small]="small"
  [class._white]="white"
  [class._alert]="alert"
  [class._warning]="warning"
  [class._highlight]="highlight"
  [class._success]="success"
  [disabled]="disabled || loading"
  (click)="btnClick.emit($event)"
>
  <i [ngClass]="faIcon" *ngIf="faIcon"></i>
  <a-button-text [labelKey]="labelKey" [loading]="loading"></a-button-text>
</button>
