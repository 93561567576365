import {ClassedEntity} from '../../../../../../domain/ClassedEntity';
import {SelectableRef} from '../../../../../../domain/SelectableRef';
import {Reference} from '../../../../../../domain/Reference';

export class ControlFamily extends ClassedEntity {
  public static readonly MODULE: string = 'core';
  public static readonly COLLECTION: string = 'controlFamily';

  public name: string;

  constructor() {
    super('com.beclm.core.api.person.control.controlFamily');
  }

  public static toSelectableRef(
    reference: Reference<ControlFamily>
  ): SelectableRef<ControlFamily> {
    const selectableRef = new SelectableRef<ControlFamily>();
    selectableRef.id = reference;
    if (reference.isResolved()) {
      selectableRef.__label = reference.entity.name;
    } else {
      selectableRef.__label = reference.id;
    }
    return selectableRef;
  }

  public toSelectableRef(): SelectableRef<ControlFamily> {
    const selectableRef = new SelectableRef<ControlFamily>();
    selectableRef.id = new Reference<ControlFamily>(this);
    selectableRef.__label = this.name;
    return selectableRef;
  }
}
