import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import {InputNotContainsOnlySpaceDirective} from '../../../../directives/validation/input-not-contains-only-space.directive';

@Component({
  selector: 'a-input',
  templateUrl: './a-input.component.html',
  styleUrls: ['./a-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useClass: InputNotContainsOnlySpaceDirective,
      multi: true
    }
  ]
})
export class AInputComponent implements ControlValueAccessor, AfterViewInit {
  @Input()
  public inputType = 'text';

  @Input()
  public name: string;

  @Input()
  public step: number;

  @Input()
  public label: string;

  @Input()
  public nowrap: boolean;

  @Input()
  public value: string;

  @Input()
  public placeholder = '';

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;


  @Input()
  public group: boolean;

  @Input()
  public formSubmitted: boolean;

  public hasIcon: boolean;

  @Input()
  public delete: boolean;

  @Input()
  public noLabel: boolean;

  @Input()
  public search: boolean;

  // Label in the input on the right
  @Input()
  public txtSubLabel: string;

  @Input()
  public subLabelEnd: boolean;

  @Input()
  public displayLink = false;

  @Input()
  public faIcon: string;

  @Input()
  max: number;

  @Input()
  min: number;

  public data: string;

  private onNgChange: (value: string) => void;
  private onNgTouched: () => void;

  @Output()
  public deleted: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('input') inputField: ElementRef;
  @Input() public focus = false;
  onBlur: () => void = () => {};

  ngAfterViewInit() {
    if (this.focus) {
      this.inputField.nativeElement.focus();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  registerOnBlur(fn: any): void {
    this.onBlur = fn;
  }

  writeValue(obj: string): void {
    this.data = obj;
  }

  public onChange(data: string): void {
    this.data = data;
    if (this.onNgChange){
      this.onNgChange(data);
    }
  }

  public onDelete(): void {
    this.deleted.emit();
  }
}
