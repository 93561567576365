import {Transform, Type} from 'class-transformer';
import {ReferenceTransformer} from '../../../../../../domain/transformation/ReferenceTransformer';
import {PersonDataSource} from '../../data-source/PersonDataSource';
import {Reference} from '../../../../../../domain/Reference';
import {SortOrder} from '../../../../../../domain/Sorter';
import {HeadcountRange} from '../../../../../core-shared/domain/person/HeadcountRange';
import {CodedValue} from '../CodedValue';
import {State} from '../../../../../../domain/State';
import {AbstractCriteria} from '../../../../../../domain/AbstractCriteria';
import {ControlType} from '../control/ControlType';

export class LegalEntitySearchParam extends AbstractCriteria {
  public readonly '@class': string = "com.beclm.core.api.person.legalEntity.LegalEntitySearchCriteria";
  externalRefId: string;
  name: string;
  sirenCode: string;
  oriasId: string;
  @Type(() => CodedValue)
  naf: CodedValue[] = [];
  headcountRange: HeadcountRange[] = [];
  @Transform(ReferenceTransformer.transformTo(PersonDataSource))
  personDataSourceRefs: Reference<PersonDataSource>[] = [];
  controlStatuses: {[key: string]: State[]} = {};

  // Filters only used in indicator person search
  controlType: ControlType;
  minScore: number;
  maxScore: number;

  currentPage: number;
  nbOfResultsPerPage: number;
  sort: LegalEntitySearchSort;
  sortOrder: SortOrder;
  ergonomicSearch = false;
}

export enum LegalEntitySearchSort {
  BY_EXTERNAL_REF_ID = 'BY_EXTERNAL_REF_ID',
  BY_NAME = 'BY_NAME',

  // Sort only used in person search
  BY_SIREN = 'BY_SIREN',

  // Sorts only used in indicator person search
  BY_DESCRIPTION = 'BY_DESCRIPTION',
  BY_SCORE = 'BY_SCORE'
}
