import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ControlValueAccessor,
  NG_ASYNC_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import {SireneService} from '../../../modules/core/services/person/sirene.service';
import {combineLatest, Observable, of} from 'rxjs';
import {SirenIsAllowedWithNafValidator} from '../../../validators/siren-is-allowed-with-naf.validator';
import {SirenIsStruckOffValidator} from '../../../validators/siren-is-struck-off.validator';
import {switchMap} from 'rxjs/operators';
import {SirenIsValidValidator} from '../../../validators/siren-is-valid.validator';
import {UniqueSirenValidator} from '../../../validators/unique-siren.validator';
import {SirenData} from '../../../modules/core/domain/person/person/external-api-data/siren/SirenData';

const CB_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MSirenCheckInputComponent),
  multi: true
};

const SIREN_ASYNC_VALIDATORS = {
  provide: NG_ASYNC_VALIDATORS,
  useExisting: forwardRef(() => MSirenCheckInputComponent),
  multi: true
};

@Component({
  selector: 'm-siren-check-input',
  templateUrl: './m-siren-check-input.component.html',
  styleUrls: ['./m-siren-check-input.component.scss'],
  host: {class: 'cell grid-x small-24 grid-padding-x'},
  providers: [CB_VALUE_ACCESSOR, SIREN_ASYNC_VALIDATORS]
})
export class MSirenCheckInputComponent
  implements ControlValueAccessor, AsyncValidator, OnInit, OnChanges {
  @Input()
  public editable = false;
  @Input()
  public originalSiren = '';
  @Input()
  public checkSirenPlaceHolder;
  @Input()
  public personDataSourceName: string;
  @Input()
  public required = false;
  @Input()
  public displaySirenDataInfo = true;
  @Input()
  public isCompany = false;
  @Input()
  public checkExistingSiren = false;
  @Input()
  public whitelistSirenByNaf = false;
  @Input()
  public checkStruckOffSiren = false;
  @Input()
  public checkPublicationStatus = false;
  @Input()
  public forceCheckSiren = false;

  public siren: string;
  public sirenData: SirenData = null;
  public checkSirenError = false;

  @Output()
  private sirenChange = new EventEmitter<string>();

  @Output()
  private sirenDataChange = new EventEmitter<SirenData>();

  private sirenIsAllowedWithNafValidator: SirenIsAllowedWithNafValidator;
  public sirenHasInvalidNaf = false;
  private sirenIsStruckOffValidator: SirenIsStruckOffValidator;
  public sirenIsStruckOff = false;
  private sirenIsValidValidator: SirenIsValidValidator;
  public sirenIsInvalid = false;
  private sirenIsUniqueValidator: UniqueSirenValidator;
  public sirenIsUnique = false;
  public hasErrors = false;

  constructor(private sireneService: SireneService) {}

  ngOnInit(): void {
    this.initAllValidators();
  }

  private initAllValidators(): void {
    this.sirenIsAllowedWithNafValidator = new SirenIsAllowedWithNafValidator(
      this.sireneService,
      this.whitelistSirenByNaf
    );
    this.sirenIsStruckOffValidator = new SirenIsStruckOffValidator(
      this.sireneService,
      this.checkStruckOffSiren
    );
    this.sirenIsValidValidator = new SirenIsValidValidator();
    this.sirenIsUniqueValidator = new UniqueSirenValidator(this.sireneService);
    this.sirenIsUniqueValidator.setInitialSiren(this.originalSiren);
    this.sirenIsUniqueValidator.setPersonDataSourceName(
      this.personDataSourceName
    );
    this.sirenIsUniqueValidator.setIsCompany(this.isCompany);
    this.sirenIsUniqueValidator.setCheckExistingSiren(this.checkExistingSiren);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateFieldInValidator(changes);
  }

  private updateFieldInValidator(changes: SimpleChanges) {
    if (this.sirenIsUniqueValidator != null && changes.originalSiren) {
      this.sirenIsUniqueValidator.setInitialSiren(this.originalSiren);
    }

    if (this.sirenIsUniqueValidator != null && changes.personDataSourceName) {
      this.sirenIsUniqueValidator.setPersonDataSourceName(
        this.personDataSourceName
      );
    }

    if (this.sirenIsUniqueValidator != null && changes.isCompany) {
      this.sirenIsUniqueValidator.setIsCompany(this.isCompany);
    }

    if (this.sirenIsUniqueValidator != null && changes.checkExistingSiren) {
      this.sirenIsUniqueValidator.setCheckExistingSiren(
        this.checkExistingSiren
      );
    }
  }

  public checkSiren(): void {
    this.checkSirenError = false;
    this.sireneService.getCompanyInfoWithSiren(this.siren).subscribe(
      (sirenData) => {
        this.sirenData = sirenData;
        this.sirenDataChange.emit(this.sirenData);
      },
      () => {
        this.sirenData = null;
        this.checkSirenError = true;
        this.sirenDataChange.emit(this.sirenData);
      }
    );
  }

  public onSirenChange(siren: string): void {
    this.siren = siren;
    this.sirenChange.emit(siren);
    this.sirenData = null;
    this.sirenDataChange.emit(this.sirenData);
    this.onChange(siren);
  }

  onChange = (_: any) => {};

  onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.editable = isDisabled;
  }

  writeValue(siren: string): void {
    this.siren = siren;

    if (siren == undefined) {
      this.sirenData = null;
    } else if (this.forceCheckSiren) {
      this.checkSiren();
    }
  }

  private reset() {
    this.sirenHasInvalidNaf = false;
    this.sirenIsStruckOff = false;
    this.sirenIsInvalid = false;
    this.sirenIsUnique = false;
    this.hasErrors = false;
  }

  validate(
    control: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    this.reset();
    return combineLatest([
      this.sirenIsAllowedWithNafValidator.validate(control).pipe(
        switchMap((result) => {
          this.sirenHasInvalidNaf = result != null;
          this.hasErrors = true;
          return of(result);
        })
      ),
      this.sirenIsStruckOffValidator.validate(control).pipe(
        switchMap((result) => {
          this.sirenIsStruckOff = result != null;
          this.hasErrors = true;
          return of(result);
        })
      ),
      of(this.sirenIsValidValidator.validate(control)).pipe(
        switchMap((result) => {
          this.sirenIsInvalid = result != null;
          this.hasErrors = true;
          return of(result);
        })
      ),
      this.sirenIsUniqueValidator.validate(control).pipe(
        switchMap((result) => {
          this.sirenIsUnique = result != null;
          this.hasErrors = true;
          return of(result);
        })
      )
    ]).pipe(
      switchMap(
        ([nafValidator, struckOffValidator, sirenIsInvalid, sirenIsUnique]) => {
          return of({
            ...nafValidator,
            ...struckOffValidator,
            ...sirenIsInvalid,
            ...sirenIsUnique
          });
        }
      )
    );
  }
}
