import {CategoryName} from './CategoryName';
import {RegistrationStatus} from './RegistrationStatus';
import {ArrayOfBankActivity} from './ArrayOfBankActivity';
import {ArrayOfMandator} from './ArrayOfMandator';
import {Type} from 'class-transformer';

export class Registration {
  categoryName: CategoryName;
  status: RegistrationStatus;
  @Type(() => Date)
  registrationDate: Date;
  @Type(() => Date)
  deletionDate: Date;
  isCollectFunds: boolean;
  @Type(() => ArrayOfBankActivity)
  bankActivities: ArrayOfBankActivity;
  @Type(() => ArrayOfMandator)
  mandators: ArrayOfMandator;
}
