import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {PersonDataSourceService} from './person-data-source.service';
import {LegalEntitySearchParam} from '../../domain/person/person/legal-entity/LegalEntitySearchParam';
import {LegalEntity} from '../../domain/person/person/legal-entity/LegalEntity';
import {HttpHandlerService} from '../../../../services/http/http-handler.service';
import {ModuleService} from '../../../../services/module.service';
import {SearchParams} from '../../../../domain/SearchParams';
import {
  PaginatedLegalEntitySearchElements
} from '../../domain/person/person/legal-entity/PaginatedLegalEntitySearchElements';
import {LegalEntityIntegratedData} from '../../domain/person/person/legal-entity/LegalEntityIntegratedData';
import {BasePersonService} from './base-person.service';
import {PersonType} from '../../domain/person/person/PersonType';
import {PaginatedIndicatorLegalEntitySearchElements} from '../../domain/person/person/control/PaginatedIndicatorLegalEntitySearchElements';

@Injectable()
export class LegalEntityService extends BasePersonService<LegalEntity> {
  protected override readonly baseUrl = '/api/legal-entities';
  protected override _currentPerson = new BehaviorSubject<LegalEntity>(undefined);
  override personType = PersonType.LEGAL;


  constructor(
    http: HttpHandlerService,
    moduleService: ModuleService,
    personDataSourceService: PersonDataSourceService
  ) {
    super(http, moduleService, personDataSourceService, LegalEntity)
  }

  public findPaginated(
    searchParam: LegalEntitySearchParam
  ): Observable<PaginatedLegalEntitySearchElements> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaginatedLegalEntitySearchElements)
          .post<PaginatedLegalEntitySearchElements>(url + '/paginated', searchParam)
      )
    );
  }

  public findPaginatedForIndicator(
    searchParam: LegalEntitySearchParam
  ): Observable<PaginatedIndicatorLegalEntitySearchElements> {
    return this.moduleService.coreModuleBackendUrl(this.baseUrl).pipe(
      switchMap((url) =>
        this.http
          .transformResponseTo(PaginatedIndicatorLegalEntitySearchElements)
          .post<PaginatedIndicatorLegalEntitySearchElements>(url + '/paginated-for-controlType', searchParam)
      )
    );
  }

  public find(param?: LegalEntitySearchParam): Observable<LegalEntity[]> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(LegalEntity)
            .get<LegalEntity[]>(url, {params: SearchParams.toHttpParams(param)})
        )
      );
  }

  public updatePersonIntegratedData(
    personId: string,
    personIntegratedData: LegalEntityIntegratedData
  ): Observable<LegalEntity> {
    return this.moduleService
      .coreModuleBackendUrl(this.baseUrl)
      .pipe(
        switchMap((url) =>
          this.http
            .transformResponseTo(LegalEntity)
            .put<LegalEntity>(`${url}/${personId}/person-integrated-data`, personIntegratedData)
        )
      );
  }

}
