import {Transform} from 'class-transformer';
import {ReferenceTransformer} from '../../../../../domain/transformation/ReferenceTransformer';
import {Company} from '../../company/Company';
import {Reference} from '../../../../../domain/Reference';

export class PersonCoreIndicatorCompanyTotal {
  @Transform(ReferenceTransformer.transformTo(Company))
  companyRef: Reference<Company>;

  totalPerson: number;
}
