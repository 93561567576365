import {InsurerContract} from './natural/main-information/InsurerContract';
import {Type} from 'class-transformer';
import {PersonAdditionalInformation} from '../../../../blacklist-pep/domain/person/PersonAdditionalInformation';
import {AccidentEvent} from '../../../../blacklist-pep/domain/person/AccidentEvent';
import {PersonMetaData} from '../../../../blacklist-pep/domain/person/PersonMetaData';

export class PersonIntegratedData {
  @Type(() => InsurerContract)
  contracts: InsurerContract[] = [];
  @Type(() => PersonAdditionalInformation)
  personAdditionalInformation: PersonAdditionalInformation = new PersonAdditionalInformation();
  @Type(() => PersonMetaData)
  personMetaData: PersonMetaData = new PersonMetaData();
  @Type(() => AccidentEvent)
  accidentEvents: AccidentEvent[] = [];
}
