import {PersonType} from '../modules/core/domain/person/person/PersonType';
import {LegalEntitySearchParam} from '../modules/core/domain/person/person/legal-entity/LegalEntitySearchParam';
import {NaturalPersonSearchParam} from '../modules/core/domain/person/person/natural/NaturalPersonSearchParam';
import {ClassedEntity} from './ClassedEntity';
import {SelectableRef} from './SelectableRef';
import {Reference} from './Reference';
import {Type} from 'class-transformer';
import {AbstractCriteria} from './AbstractCriteria';

export class SearchParamByCompany extends ClassedEntity{
  name: string;
  @Type(() => AbstractCriteria, {
    discriminator: {
      property: '@class',
      subTypes: [
        {
          value: LegalEntitySearchParam,
          name: 'com.beclm.core.api.person.legalEntity.LegalEntitySearchCriteria'
        },
        {
          value: NaturalPersonSearchParam,
          name: 'com.beclm.core.api.person.naturalPerson.NaturalPersonSearchCriteria'
        }
      ]
    }
  })
  searchParam: LegalEntitySearchParam | NaturalPersonSearchParam;
  type: PersonType.NATURAL | PersonType.LEGAL;

  constructor() {
    super('com.beclm.core.api.company.SearchCriteriaByCompany');
  }


  public toSelectableRef(): SelectableRef<SearchParamByCompany> {
    const selectableRef = new SelectableRef<SearchParamByCompany>();
    selectableRef.id = new Reference<SearchParamByCompany>(this);
    selectableRef.__label = this.name;
    return selectableRef;
  }

}
